import postData from '../assets/js/fetch.js'
let disparado = false
let dev
process.env.NODE_ENV === 'production'
  ? (dev = '')
  : (dev = 'https://xapp.icnea.net')

const compare = (a, b) => a.getTime() >= b.getTime()
const formatDate = (date) => {
  const allMonths = JSON.parse(sessionStorage.getItem('diccionario')).monthShort
  const allWeekdays = JSON.parse(sessionStorage.getItem('diccionario'))
    .weekdayShort
  const months = [
    allMonths[0],
    allMonths[1],
    allMonths[2],
    allMonths[3],
    allMonths[4],
    allMonths[5],
    allMonths[6],
    allMonths[7],
    allMonths[8],
    allMonths[9],
    allMonths[10],
    allMonths[11],
  ]
  const week = [
    allWeekdays[0],
    allWeekdays[1],
    allWeekdays[2],
    allWeekdays[3],
    allWeekdays[4],
    allWeekdays[5],
    allWeekdays[6],
  ]

  let long_date = ''
  let day = date.slice(8, 10)
  let month = date.slice(5, 7)
  let year = date.slice(0, 4)
  let new_date = new Date(date)
  let day_week = new_date.getUTCDay()
  long_date = week[day_week] + ', ' + day + ' ' + months[month - 1] + ' ' + year

  return long_date
}
let dateNow = new Date().toISOString().slice(0, 10)

const state = {
  dashboard: [],

  // InOut //
  arrivals: [],
  departures: [],
  booking: {},
  bookingPayments: [],
  bookingGuests: [],
  itRegionBirth: [],
  itDocumentCity: [],
  newGuest: '0',
  bookingContract: [],
  calendar: [],
  calendars: [],
  filterBookings: [],
  bookings: [],

  // Cleaning //
  cleanings: [],
  cleaningDetail: [],
  numIncidencia: '',
  cleaningsCalendar: [],

  // Services //
  services: [],
  serviceDetail: [],

  // Chat //
  xatPendents: [],
  xatConversa: [],
}

const mutations = {
  setDashboard(state, dashboard) {
    state.dashboard = dashboard
  },

  // In Out //
  setArrivals(state, arrivals) {
    state.arrivals = arrivals
  },
  setDepartures(state, departures) {
    state.departures = departures
  },
  setBooking(state, booking) {
    state.booking = booking
  },
  setBookingPayments(state, bookingPayments) {
    state.bookingPayments = bookingPayments
  },
  setBookingGuests(state, bookingGuests) {
    state.bookingGuests = bookingGuests
  },
  setItRegionBirth(state, itRegionBirth) {
    state.itRegionBirth = itRegionBirth
  },
  setItDocumentCity(state, itDocumentCity) {
    state.itDocumentCity = itDocumentCity
  },
  setNewGuest(state, newGuest) {
    state.newGuest = newGuest
  },
  setBookingContract(state, bookingContract) {
    state.bookingContract = bookingContract
  },
  setCalendar(state, calendar) {
    state.calendar = calendar
  },
  setCalendars(state, calendars) {
    state.calendars = calendars
  },
  setFilterBookings(state, filterBookings) {
    state.filterBookings = filterBookings
  },
  setBookings(state, bookings) {
    state.bookings = bookings
  },

  // Cleaning //
  setCleanings(state, cleanings) {
    state.cleanings = cleanings
  },
  setCleaningDetail(state, cleaningDetail) {
    state.cleaningDetail = cleaningDetail
  },
  setNumIncidencia(state, numIncidencia) {
    state.numIncidencia = numIncidencia
  },
  setCleaningsCalendar(state, cleaningsCalendar) {
    state.cleaningsCalendar = cleaningsCalendar
  },

  // Services //
  setServices(state, services) {
    state.services = services
  },
  setServiceDetail(state, serviceDetail) {
    state.serviceDetail = serviceDetail
  },

  // Chat //
  setXatPendents(state, xatPendents) {
    state.xatPendents = xatPendents
  },
  setXatConversa(state, xatConversa) {
    state.xatConversa = xatConversa
  },
}

const actions = {
  getDashboard({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Portada', {usr}).then((data) => {
      console.log('Portada', JSON.parse(data.d).Response)
      commit('setDashboard', JSON.parse(data.d).Response)
    })
  },

  ///////////////////////////////////////////////////////////
  // InOut
  ///////////////////////////////////////////////////////////
  // Arrivals
  getArrivals({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Entrades', {usr}).then((data) => {
      console.log('Entrades', JSON.parse(data.d).Response)
      commit('setArrivals', JSON.parse(data.d).Response)
    })
  },
  // Departures
  getDepartures({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Sortides', {usr}).then((data) => {
      console.log('Sortides', JSON.parse(data.d).Response)
      commit('setDepartures', JSON.parse(data.d).Response)
    })
  },
  // Booking
  getBooking({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/ReservaResum', {
      usr,
      reserva,
    }).then((data) => {
      let reservation = JSON.parse(data.d).Response

      reservation.guests =
        parseInt(reservation.adults) + parseInt(reservation.children)
      reservation.sumAdultsChildren =
        parseInt(reservation.adults) + parseInt(reservation.children)
      if (
        parseInt(reservation.registered_guests) ===
        reservation.sumAdultsChildren
      ) {
        reservation.guestsCompleted = true
      } else {
        reservation.guestsCompleted = false
      }
      if (
        parseInt(reservation.registered_guests) >= parseInt(reservation.adults)
      ) {
        reservation._registered_adults_only_completed = true
      } else {
        reservation._registered_adults_only_completed = false
      }
      if (parseFloat(reservation.pending_payment) <= 0) {
        reservation.paymentCompleted = true
        //reservation.pending_payment_status = this.translator.pagat;
      } else {
        reservation.pending_payment_status =
          reservation.pending_payment + reservation.currency
        reservation.paymentCompleted = false
      }

      if (reservation.signed_contract === 'true') {
        reservation.signed_contract_status = true //this.translator.signat
        reservation.contractCompleted = true
      } else {
        reservation.signed_contract_status = false //this.translator.nosignat
        reservation.contractCompleted = false
      }

      if (
        reservation.status >= '6' &&
        reservation.adults <= reservation.registered_guests &&
        reservation.paymentCompleted &&
        reservation.contractCompleted
      ) {
        reservation.checkinCompleted = true
      } else {
        reservation.checkinCompleted = false
      }

      reservation.checkOutInTime = compare(
        new Date(dateNow),
        new Date(reservation.departure_date),
      )
      reservation.sumAdultsChildren =
        parseInt(reservation.adults) + parseInt(reservation.children)

      reservation._arrival_date_format = formatDate(reservation.arrival_date)
      reservation._departure_date_format = formatDate(
        reservation.departure_date,
      )
      reservation._image_format = `background-image: url(${String(
        reservation.image,
      )}); 
                                            background-size: cover; 
                                            background-repeat: no-repeat; 
                                            height: 150px;
                                            background-position: center;`

      // Seteo de horas
      if (reservation.arrival_time != '') {
        String(reservation.arrival_time).length <= 2
          ? (reservation._arrival_time = `${reservation.arrival_time}:00`)
          : (reservation._arrival_time = `${reservation.arrival_time}`)
      } else {
        reservation._arrival_time = reservation.arrival_time
      }

      if (reservation.departure_time != '') {
        String(reservation.departure_time).length <= 2
          ? (reservation._departure_time = `${reservation.departure_time}:00`)
          : (reservation._departure_time = `${reservation.departure_time}`)
      } else {
        reservation._departure_time = reservation.departure_time
      }

      // Entrada desde
      parseInt(reservation.lodging_checkin_from) == 24
        ? (reservation._lodging_checkin_from_show_input = 0)
        : (reservation._lodging_checkin_from_show_input = parseInt(
            reservation.lodging_checkin_from,
          ))
      // Entrada hasta
      parseInt(reservation.lodging_checkin_to) == 0
        ? (reservation._lodging_checkin_to_show_input = 24)
        : (reservation._lodging_checkin_to_show_input = parseInt(
            reservation.lodging_checkin_to,
          ))

      // Si los campos de entrada del PMS estan vacios muestro todas las horas
      if (
        reservation._lodging_checkin_from_show_input == 0 &&
        reservation._lodging_checkin_to_show_input == 24
      ) {
        reservation._lodging_checkin_from_show_input = 16
        reservation._lodging_checkin_to_show_input = 20
      }

      // Salida desde
      parseInt(reservation.lodging_checkout_from) == 24
        ? (reservation._lodging_checkout_from_show_input = 0)
        : (reservation._lodging_checkout_from_show_input = parseInt(
            reservation.lodging_checkout_from,
          ))
      // Salida hasta
      parseInt(reservation.lodging_checkout_to) == 0
        ? (reservation._lodging_checkout_to_show_input = 24)
        : (reservation._lodging_checkout_to_show_input = parseInt(
            reservation.lodging_checkout_to,
          ))

      // Si los campos de salida del PMS estan vacios muestro todas las horas
      if (
        reservation._lodging_checkout_from_show_input == 0 &&
        reservation._lodging_checkout_to_show_input == 24
      ) {
        reservation._lodging_checkout_from_show_input = 9
        reservation._lodging_checkout_to_show_input = 12
      }

      commit('setBooking', reservation)
      console.log('ReservaResum', reservation)
    })
  },
  postBookingSave(
    {dispatch},
    {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    },
  ) {
    postData(dev + '/WebService.asmx/ReservaGuardar', {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    }).then((data) => {
      console.log('ReservaGuardar', JSON.parse(data.d))
      if (JSON.parse(data.d) === true) {
        dispatch('getBooking', {usr, reserva: reservation})
      }
    })
  },
  deleteHostesEliminar({dispatch}, {usr, reserva, hostId}) {
    postData(dev + '/WebService.asmx/HostesEliminar', {
      usr,
      reserva,
      hostId,
    }).then((data) => {
      if (data.d === 'True') {
        dispatch('getBooking', {usr, reserva})
        dispatch('getHostes', {usr, reserva})
      }
    })
  },
  checkIn({dispatch}, {usr, reserva, ip}) {
    postData(dev + '/WebService.asmx/Checkin', {usr, reserva, ip}).then(
      (data) => {
        console.log('Checkin', data)
        dispatch('getBooking', {usr, reserva})
      },
    )
  },
  checkOut({dispatch}, {usr, reserva, ip}) {
    postData(dev + '/WebService.asmx/Checkout', {usr, reserva, ip}).then(
      (data) => {
        console.log('Checkout', data)
        dispatch('getBooking', {usr, reserva})
      },
    )
  },
  // Lectura DNI
  postPhotoDNI({dispatch, commit}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesPassaport', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      commit('setNewGuest', data.d)
      dispatch('getBooking', {usr, reserva})
      dispatch('getHostes', {usr, reserva})
    })
  },
  //Firma huésped
  postSignGuest({dispatch}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesSignatura', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      console.log('HostesSignatura', data.d)
      if (data.d == true) {
        dispatch('getBooking', {usr, reserva})
        dispatch('getHostes', {usr, reserva})
      }
    })
  },
  // Foto anverso y reverso DNI posición 1 = reverso 2 = anverso
  postSavePhotoFrontPassport(
    {dispatch},
    {usr, reserva, hostId, posicio, base64},
  ) {
    postData(dev + '/WebService.asmx/HostesPassaportFoto', {
      usr,
      reserva,
      hostId,
      posicio,
      base64,
    }).then((data) => {
      data.d == true ? dispatch('getHostes', {usr, reserva}) : ''
    })
  },
  // Foto selfie
  postSaveSelfie({dispatch}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesFoto', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      data.d == true ? dispatch('getHostes', {usr, reserva}) : ''
    })
  },
  //Guest_guests
  postAddGuest(
    {dispatch},
    {
      usr,
      reserva,
      hostId,
      nom,
      cognom1,
      cognom2,
      sexe,
      tipus,
      naixement_data,
      naixement_pais,
      naixement_lloc,
      naixement_regio,
      document_numero,
      document_suport,
      document_tipus,
      document_data,
      document_pais,
      document_lloc,
      adreca_pais,
      adreca_lloc,
      adreca_poble,
      adreca_dip,
    },
  ) {
    if (!disparado) {
      disparado = true
      postData(dev + '/WebService.asmx/HostesGuardar', {
        usr,
        reserva,
        hostId,
        nom,
        cognom1,
        cognom2,
        sexe,
        tipus,
        naixement_data,
        naixement_pais,
        naixement_lloc,
        naixement_regio,
        document_numero,
        document_suport,
        document_tipus,
        document_data,
        document_pais,
        document_lloc,
        adreca_pais,
        adreca_lloc,
        adreca_poble,
        adreca_dip,
      }).then((data) => {
        console.log('HostesGuardar', JSON.parse(data.d))
        if (JSON.parse(data.d) === true) {
          dispatch('getBooking', {usr, reserva})
          dispatch('getHostes', {usr, reserva})
        }
      })

      setTimeout(() => {
        disparado = false
      }, 1000)
    }
  },
  getHostesRegionsIT({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/HostesRegionsIT', {usr, reserva}).then(
      (data) => {
        console.log('HostesRegionsIT', JSON.parse(data.d).Response)
        commit('setItRegionBirth', JSON.parse(data.d).Response)
      },
    )
  },
  getHostesPoblesIT({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/HostesPoblesIT', {usr, reserva}).then(
      (data) => {
        console.log('HostesPoblesIT', JSON.parse(data.d).Response)
        commit('setItDocumentCity', JSON.parse(data.d).Response)
      },
    )
  },
  getHostes({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/Hostes', {usr, reserva}).then((data) => {
      // Comprobación de los campos obligatorios de cada huésped
      let people = JSON.parse(data.d).Response
      people.forEach((guests) => {
        let isCorrect = true
        //let isSpanish = true;

        //guests.document_country === 'ES' ? isSpanish = true : isSpanish = false;
        guests._isAdult = isAdult(guests.guest_birthday_date)
        guests.guest_signature == ''
          ? (guests._isSign = false)
          : (guests._isSign = true)

        for (const x in guests) {
          x === 'guest_first_name' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'guest_last_name_one' && guests[x] == ''
            ? (isCorrect = false)
            : ''
          // if (isSpanish) {
          //     x === "guest_last_name_two" && guests[x] == '' ? isCorrect = false : '';
          // }
          x === 'guest_sex' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'guest_birthday_date' && guests[x] == ''
            ? (isCorrect = false)
            : ''
          x === 'document_number' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_type' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_country' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_date' && guests[x] == '' ? (isCorrect = false) : ''
        }
        isCorrect ? (guests._input_empty = false) : (guests._input_empty = true)
      })

      function isAdult(fecha) {
        let hoy = new Date()
        let birth = new Date(fecha)
        let edad = hoy.getFullYear() - birth.getFullYear()
        let diferenciaMeses = hoy.getMonth() - birth.getMonth()
        if (
          diferenciaMeses < 0 ||
          (diferenciaMeses === 0 && hoy.getDate() < birth.getDate())
        ) {
          edad--
        }
        let isAdult = false
        edad >= 18 ? (isAdult = true) : (isAdult = false)
        return isAdult
      }

      commit('setBookingGuests', people)
      console.log('Hostes', people)
    })
  },

  // Booking_payment
  getBookingPayments({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/ReservaDetall', {usr, reserva}).then(
      (data) => {
        console.log('ReservaDetall', JSON.parse(data.d).Response)
        commit('setBookingPayments', JSON.parse(data.d).Response)
      },
    )
  },
  postCardPayment({dispatch}, {usr, reserva, cobrar, diposit, paypal}) {
    postData(dev + '/WebService.asmx/Banc', {
      usr,
      reserva,
      cobrar,
      diposit,
      paypal,
    }).then((data) => {
      console.log('Banc', data.d)
      if (data.d != '') {
        dispatch('getBookingPayments', {usr, reserva})
        window.location.href = data.d
      }
    })
  },
  postCashPayment(
    {dispatch},
    {usr, reserva, importPagar, tipus, data, observacions},
  ) {
    postData(dev + '/WebService.asmx/ReservaPagament', {
      usr,
      reserva,
      import: importPagar,
      tipus,
      data,
      observacions,
    }).then((data) => {
      console.log('ReservaPagament', data.d)
      dispatch('getBookingPayments', {usr, reserva})
    })
  },

  //Cargo checkin por llegar tarde
  postCheckinCarrec({dispatch}, {usr, reserva, carrec, ip}) {
    postData(dev + '/WebService.asmx/CheckinCarrec', {
      usr,
      reserva,
      import: carrec,
      ip,
    }).then((data) => {
      console.log('CheckinCarrec', data.d)
      data.d ? dispatch('getBookingPayments', {usr, reserva}) : ''
    })
  },

  // Booking_contract
  getBookingContract({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/Contracte', {usr, reserva})
      .then((data) => {
        if (data.d != '') {
          console.log('Contracte', JSON.parse(data.d).Response)
          commit('setBookingContract', JSON.parse(data.d).Response)
        } else {
          console.log('ContracteRaw', data)
          commit('setBookingContract', '')
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  },
  postContracteSignatura({dispatch}, {usr, reserva, jpg, ip, language}) {
    postData(dev + '/WebService.asmx/ContracteSignatura', {
      usr,
      reserva,
      jpg,
      ip,
    }).then((data) => {
      console.log('ContracteSignatura', JSON.parse(data.d).Response)
      dispatch('getBookingContract', {usr, reserva, language})
    })
  },

  getFilterBookings({commit}, {usr}) {
    postData(dev + '/WebService.asmx/ReservesFiltres', {usr}).then((data) => {
      console.log('ReservesFiltres', JSON.parse(data.d).Response)
      commit('setFilterBookings', JSON.parse(data.d).Response)
    })
  },

  getBookings(
    {commit},
    {
      usr,
      datefrom,
      dateto,
      datetype,
      type,
      country,
      region,
      channel,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    },
  ) {
    postData(dev + '/WebService.asmx/Reserves', {
      usr,
      datefrom,
      dateto,
      datetype,
      type,
      country,
      region,
      channel,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    }).then((data) => {
      data.d != ''
        ? console.log('Reserves', JSON.parse(data.d).Response)
        : console.log('ReservesRaw', data)
      commit('setBookings', JSON.parse(data.d).Response)
    })
  },

  // Calendar // Usado por Checkin y Service
  getCalendar({commit}, {usr, data}) {
    postData(dev + '/WebService.asmx/Calendari', {usr, data}).then((data) => {
      console.log('Calendari', JSON.parse(data.d).Response)
      commit('setCalendar', JSON.parse(data.d).Response)
    })
  },

  // Calendars availability //
  getCalendars(
    {commit},
    {
      usr,
      datefrom,
      dateto,
      country,
      region,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    },
  ) {
    postData(dev + '/WebService.asmx/Calendaris', {
      usr,
      datefrom,
      dateto,
      country,
      region,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    }).then((data) => {
      console.log('Calendars', JSON.parse(data.d).Response)
      commit('setCalendars', JSON.parse(data.d).Response)
    })
  },
  postBloquejarCalendari({dispatch}, {usr, data, lodgingId, nits, bloquejar}) {
    // dispatch
    // datefrom, dateto, country, region, group, owner, properties, management, typology, capacity
    postData(dev + '/WebService.asmx/BloquejarCalendari', {
      usr,
      data,
      lodgingId,
      nits,
      bloquejar,
    }).then((data) => {
      console.log('BloquejarCalendari', data.d)
      console.log(dispatch)
      //data.d ? dispatch('getCalendars', { usr, datefrom, dateto, country, region, group, owner, properties, management, typology, capacity }) : null;
    })
  },
  postReservaAfegir(
    {dispatch},
    {
      usr,
      nom,
      cognom,
      telefon,
      email,
      adults,
      joves,
      bebes,
      observacions,
      lodgingId,
      entrada,
      nits,
      preu,
    },
  ) {
    // dispatch
    // datefrom, dateto, country, region, group, owner, properties, management, typology, capacity
    postData(dev + '/WebService.asmx/ReservaAfegir', {
      usr,
      nom,
      cognom,
      telefon,
      email,
      adults,
      joves,
      bebes,
      observacions,
      lodgingId,
      entrada,
      nits,
      preu,
    }).then((data) => {
      console.log('ReservaAfegir', data.d)
      console.log(dispatch)
      //data.d ? dispatch('getCalendars', { usr, datefrom, dateto, country, region, group, owner, properties, management, typology, capacity }) : null;
    })
  },
  postReservaActualitzar(
    {dispatch},
    {
      vm,
      trad,
      usr,
      reserva,
      nom,
      cognom,
      telefon,
      email,
      adults,
      joves,
      bebes,
      observacions,
      lodgingId,
      entrada,
      nits,
      estada,
      earlybooking_discount,
      longstay_discount,
      lastminute_discount,
      standard_discount,
      promotion_discount,
      booking_fee,
      cleaning_fee,
      checkin_fee,
      shortstay_fee,
      tourist_tax,
      finance_fee,
      diposit,
    },
  ) {
    postData(dev + '/WebService.asmx/ReservaActualitzar', {
      usr,
      reserva,
      nom,
      cognom,
      telefon,
      email,
      adults,
      joves,
      bebes,
      observacions,
      lodgingId,
      entrada,
      nits,
      estada,
      earlybooking_discount,
      longstay_discount,
      lastminute_discount,
      standard_discount,
      promotion_discount,
      booking_fee,
      cleaning_fee,
      checkin_fee,
      shortstay_fee,
      tourist_tax,
      finance_fee,
      diposit,
    }).then((data) => {
      console.log('ReservaActualitzar', data.d)
      if (data.d) {
        vm.$toast.success(trad)
        dispatch('getBooking', {usr, reserva})
      }
    })
  },

  ///////////////////////////////////////////////////////////
  // Cleaning
  ///////////////////////////////////////////////////////////
  getCleanings({commit}, {usr, data}) {
    postData(dev + '/WebService.asmx/Netejes', {usr, data}).then((data) => {
      let array = []
      JSON.parse(data.d).Response.dates.forEach((neteja) => {
        neteja.expanded = true
        array.push(neteja)
      })

      const neteges = {dates: array}
      console.log('Netejes', neteges)
      commit('setCleanings', neteges)
    })
  },
  getCleaningDetail({commit}, {usr, id, data}) {
    postData(dev + '/WebService.asmx/NetejesDetall', {usr, id, data}).then(
      (data) => {
        console.log('NetejesDetall', JSON.parse(data.d).Response)
        commit('setCleaningDetail', JSON.parse(data.d).Response)
      },
    )
  },
  postCleaningStatus({dispatch}, {usr, id, final, fecha, lat, lng}) {
    console.log(usr, id, final, fecha)
    postData(dev + '/WebService.asmx/NetejesEstat', {
      usr,
      id,
      final,
      lat,
      lng,
    }).then((data) => {
      if (data.d == true) {
        dispatch('getCleaningDetail', {usr, id, data: fecha})
      }
    })
  },
  postIncidencia({commit}, {usr, lodging_id, titular, texte, motiu}) {
    console.log(usr, lodging_id, titular, texte, motiu)
    postData(dev + '/WebService.asmx/ServeiCrear', {
      usr,
      lodgingId: lodging_id,
      titular,
      texte,
      motiu,
    }).then((data) => {
      commit('setNumIncidencia', data.d)
    })
  },
  postNetejaEnviarFotos(
    {dispatch},
    {usr, id, data, image1, image2, image3, idUrl},
  ) {
    const image4 = '',
      image5 = '',
      image6 = ''
    if (image1 == '' && image2 == '' && image3 == '') {
      dispatch('getCleaningDetail', {usr, id: idUrl, data})
    } else {
      postData(dev + '/WebService.asmx/ServeiImatge', {
        usr,
        id,
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        data,
      }).then((datos) => {
        console.log('rawServeiImatge', datos)
        if (datos.d === true) {
          dispatch('getCleaningDetail', {usr, id: idUrl, data})
        }
      })
    }
  },
  getCleaningsCalendar({commit}, {usr, data}) {
    postData(dev + '/WebService.asmx/NetejesCalendari', {usr, data}).then(
      (data) => {
        console.log('NetejesCalendari', JSON.parse(data.d).Response)
        commit('setCleaningsCalendar', JSON.parse(data.d).Response)
      },
    )
  },

  ///////////////////////////////////////////////////////////
  // Service
  ///////////////////////////////////////////////////////////
  getService({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Servei', {usr}).then((data) => {
      console.log('Servei', JSON.parse(data.d).Response)
      commit('setServices', JSON.parse(data.d).Response)
    })
  },
  getServiceDetail({commit}, {usr, id}) {
    postData(dev + '/WebService.asmx/ServeiDetall', {usr, id}).then((data) => {
      console.log('ServeiDetall', JSON.parse(data.d).Response)
      commit('setServiceDetail', JSON.parse(data.d).Response)
    })
  },
  postServeiEnviarFotos({dispatch}, {usr, id, image4, image5, image6}) {
    const image1 = '',
      image2 = '',
      image3 = ''
    postData(dev + '/WebService.asmx/ServeiImatge', {
      usr,
      id,
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
    }).then((data) => {
      console.log('ServeiImatge', data)
      dispatch('getServiceDetail', {usr, id})
    })
  },
  postServeiGuardarInforme({dispatch}, {usr, id, informe, preu}) {
    postData(dev + '/WebService.asmx/ServeiGuardar', {
      usr,
      id,
      informe,
      import: preu,
    }).then((data) => {
      console.log('ServeiGuardar', data.d)
      data.d == true ? dispatch('getServiceDetail', {usr, id}) : ''
    })
  },
  postEndService({dispatch}, {usr, id}) {
    postData(dev + '/WebService.asmx/ServeiFinal', {usr, id}).then((data) => {
      console.log('ServeiFinal', data.d)
      data.d == true ? dispatch('getServiceDetail', {usr, id}) : ''
    })
  },

  ///////////////////////////////////////////////////////////
  // Chat
  ///////////////////////////////////////////////////////////
  getChatPendents({commit}, {usr}) {
    postData(dev + '/WebService.asmx/XatPendents', {usr}).then((data) => {
      // let chats = []
      // let getChats = JSON.parse(data.d).Response;
      // let indice;

      // // Compruebo si la misma reserva tiene varios msn y los agrupo
      // getChats.forEach((chat) => {
      //     let exist = false;
      //     chat.num_messages = 1;

      //     //Si existe sumo +1 al msn si no lo añado al array
      //     chats.forEach((c, index) => {
      //         if ( String(c.arrival_date) === String(chat.arrival_date) && parseInt(c.lodging_id) === parseInt(chat.lodging_id) && String(c.customer_name) === String(chat.customer_name) && String(c.lodging_name) === String(chat.lodging_name) ) {
      //             exist = true;
      //             indice = index;
      //         }
      //     })

      //     !exist ? chats.push( chat ) : chats[indice].num_messages ++;
      // })

      commit('setXatPendents', JSON.parse(data.d).Response)
      console.log('XatPendents', JSON.parse(data.d).Response)
    })
  },
  getChat({commit}, {usr}) {
    postData(dev + '/WebService.asmx/XatConversa', {usr}).then((data) => {
      data.d == ''
        ? console.log('XatConversaRAW', data)
        : console.log('XatConversa', JSON.parse(data.d).Response)
      data.d == ''
        ? commit('setXatConversa', '')
        : commit('setXatConversa', JSON.parse(data.d).Response)
    })
  },
  postSendMessage({dispatch}, {usr, text, ip}) {
    postData(dev + '/WebService.asmx/XatResposta', {usr, text, ip}).then(
      (data) => {
        console.log('XatResposta', data.d)
        dispatch('getChat', {usr})
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
